import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useRouter } from 'next/router';
import { Text } from '../Text';
import { useSearchStore } from '../../providers/RootStoreProvider';
import { Card } from '../Card';
import { CruiseType } from '../SearchResults/components/CardList/CardList';
import styles from './PopularCruises.module.sass';
import { StorageManager } from '../../lib/StorageManager';
import { Pagination } from '../Pagination';
import { SkeletonCard } from '../Skeleton';
import { YaAndGaMainPage } from '../../lib/YaAndGaHelper';
import { PopCruisesSkeleton } from '../PopCruisesSkeleton/PopCruisesSkeleton';

dayjs.extend(advancedFormat);

const checkIsViewed = id => {
  const viewed = StorageManager.get('viewed') ?? [];
  if (viewed.length > 0) {
    return viewed.some(i => +i === +id);
  }
  return false;
};

export const PopularCruises = observer(
  ({ isMobile, metriks, popularCruises }) => {
    const store = useSearchStore();
    const router = useRouter();
    const { popularCards, popularPageCount } = store;
    const isLoading = store.popularIsLoading;
    const getPopularCruises = page => store.getPopularCruises(page);
    const { type } = router.query;
    const [pageCount, setPageCount] = React.useState(null);

    useEffect(() => {
      getPopularCruises();
    }, []);

    useEffect(() => {
      if (popularPageCount !== undefined) {
        setPageCount(popularPageCount);
      }
    }, [popularPageCount]);
    return (
      <>
        {popularCards.length !== 0 || !isLoading ? (
          <div className={styles.popularCruises}>
            {!isMobile ? (
              <>
                <div className={styles.popularCruisesHeader}>
                  <Text variant="heading-1">Популярные круизы</Text>
                  <Pagination
                    isLoading={isLoading}
                    popularPageCount={pageCount}
                    onChange={event => getPopularCruises(event)}
                    onClick={() => {
                      YaAndGaMainPage('click_current_page_popular_cruises');
                    }}
                  />
                </div>
                <div className={styles.listCard}>
                  {popularCards.map(cruise =>
                    !isLoading ? (
                      <Card
                        rewriteUrlImage={false}
                        id={cruise.id}
                        category={cruise.category_id}
                        start={cruise.kr_start_city}
                        end={cruise.kr_finish_city}
                        shipId={cruise.ship_id}
                        title={cruise.t_title}
                        key={cruise.id}
                        route={cruise.route_full}
                        places={cruise.free_places_true}
                        images={cruise.route_img_new}
                        startDate={cruise.kr_date_start}
                        endDate={cruise.kr_date_end}
                        days={cruise.kr_days}
                        night={cruise.kr_nights}
                        beforeRise={cruise.days_before_rise}
                        price={cruise.min_price_discounted}
                        oldPrice={cruise.min_price}
                        link={cruise.id}
                        cashback={cruise.cashback}
                        discounts={cruise.explicit_discounts}
                        specialNote={cruise.special_note}
                        sea={type === CruiseType.Sea}
                        viewed={checkIsViewed(cruise.id)}
                        isMobile={isMobile}
                        shopingBadge={cruise?.shoppingdayBadge}
                        blackFriday={cruise?.black_friday}
                        isSpecialOffer={cruise?.special_offer}
                        specialOfferValue={cruise?.special_offer_value}
                        popularCruises
                        metriks={metriks}
                      />
                    ) : (
                      <SkeletonCard />
                    ),
                  )}
                </div>
              </>
            ) : (
              <>
                <Text variant="heading-1">Популярные круизы</Text>
                <div className={styles.cardScrollContainer}>
                  {!isLoading ? (
                    popularCards.map(cruise => (
                      <div key={cruise.id} className={styles.cardScrollItem}>
                        <Card
                          rewriteUrlImage={false}
                          id={cruise.id}
                          category={cruise.category_id}
                          start={cruise.kr_start_city}
                          end={cruise.kr_finish_city}
                          shipId={cruise.ship_id}
                          title={cruise.t_title}
                          route={cruise.route_full}
                          places={cruise.free_places_true}
                          images={cruise.route_img_new}
                          startDate={cruise.kr_date_start}
                          endDate={cruise.kr_date_end}
                          days={cruise.kr_days}
                          night={cruise.kr_nights}
                          beforeRise={cruise.days_before_rise}
                          price={cruise.min_price_discounted}
                          oldPrice={cruise.min_price}
                          link={cruise.id}
                          cashback={cruise.cashback}
                          discounts={cruise.explicit_discounts}
                          specialNote={cruise.special_note}
                          sea={type === CruiseType.Sea}
                          viewed={checkIsViewed(cruise.id)}
                          popularCruises
                          isMobile={isMobile}
                          shopingBadge={cruise?.shoppingdayBadge}
                          blackFriday={cruise?.black_friday}
                          isSpecialOffer={cruise?.special_offer}
                          specialOfferValue={cruise?.special_offer_value}
                        />
                      </div>
                    ))
                  ) : (
                    <SkeletonCard />
                  )}
                </div>
                <div className={styles.mobilePagination}>
                  <Pagination
                    isLoading={isLoading}
                    popularPageCount={pageCount}
                    onChange={event => getPopularCruises(event)}
                    onClick={() => {
                      YaAndGaMainPage('click_current_page_popular_cruises');
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <PopCruisesSkeleton />
        )}
      </>
    );
  },
);
